.fullwidth {
    left: 0;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    right: 0;
    width: 100%;
}

.skin-purple .wrapper {
    height: 100vh!important;
}

#services {
    padding-top: 0;
}

#services .row.icon-padding{
    background-color: #4d4d4d;
    padding-top: 75px;
}

#services .row .service-box .fa-stack .fa-stack-2x  {
    background-color: #5949c5;
    border-radius: 50%;
    color: #fff;
}

#services .row .service-box .fa-stack .fa-stack-1x {
    color: #5949c5;
}

@media (max-width: 768px) {
    #services .row {
        margin-top: -25px;
    }
}

hr {
    border-color: #004471;
}

header:not(.header-light) a {
    color: #fff;
}

a:hover,
a:focus,
a:active,
header:not(.header-light) a:hover,
header:not(.header-light) a:focus,
header:not(.header-light) a:active {
    color: #fb3c3e;
}

.form-control {
    border-radius: 4px !important;
    height: 40px;
    margin-bottom: 20px;
}

textarea.form-control {
    height: 160px;
    padding-top: 12px;
}

.btn {
    padding-top: 12px;
}

.btn-default {
    color: #444 !important;;
}

.btn-clear.light {
    background: transparent;
    border: 2px solid #fff;
    border-radius: 100% !important;
    color: #fff;
    font-size: 40px;
    height: 70px;
    margin-top: 10px;
    padding: 7px 16px;
    -moz-transition: background .3s ease-in-out;
    -webkit-transition: background .3s ease-in-out;
    width: 70px;
}

.btn-subscribe {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
}

.form-subscribe {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.checkbox input[type="checkbox"]:checked + label::after,
.checkbox input[type="radio"]:checked + label::after {
    font-weight: 900;
    font-family: "Font Awesome 6 Pro";
    content: "\f00c";
}

.checkbox-primary input[type="checkbox"]:checked + label::before {
    background-color: #5949c5;
    border-color: #5949c5;
}

.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    left: 0;
    border: 1px solid #cccccc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}

.checkbox label::after {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    left: 0;
    top: 0;
    padding-left: 3px;
    padding-top: 1px;
    font-size: 11px;
    color: #fff;
}

.checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
    margin-left: 0;
}

.checkbox label {
    padding-left: 25px;
}

/* ==================================================
CSS-only corner-ribbon styles
================================================== */
.cnrflash {
    border-radius: 3px 5px 3px 0;
    height: 100px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: -9px;
    width: 100px;
    z-index: 1;
}

.cnrflash-inner {
    background-color: #aa0101;
    background-image: linear-gradient(90deg, transparent 50%, rgba(255, 255, 255, .1)
        50%), linear-gradient(0deg, transparent 0%, rgba(1, 1, 1, .2)
        50%);
    background-size: 4px, auto, auto, auto;
    bottom: 0;
    box-shadow: 0 3px 3px 0 rgba(1, 1, 1, .5), 0 1px 0 0 rgba(1, 1, 1, .5),
        inset 0 -1px 8px 0 rgba(255, 255, 255, .3), inset 0 -1px 0 0
        rgba(255, 255, 255, .2);
    height: 145px;
    position: absolute;
    right: 0;
    -moz-transform: rotate(45deg); /* Firefox */
    -ms-transform: rotate(45deg); /* IE 9 */
    -o-transform: rotate(45deg); /* Opera */
    -webkit-transform: rotate(45deg); /* Safari and Chrome */
    -moz-transform-origin: 100% 100%; /* Firefox */
    -ms-transform-origin: 100% 100%; /* IE 9 */
    -o-transform-origin: 100% 100%; /* Opera */
    -webkit-transform-origin: 100% 100%;
    width: 145px;
}

.cnrflash-inner:before, .cnrflash-inner:after {
    border: 8px solid #800000;
    bottom: -16px;
    content: " ";
    display: block;
    height: 0;
    position: absolute;
    width: 0;
}

.cnrflash-inner:before {
    border-bottom-color: transparent;
    border-right-color: transparent;
    left: 1px;
}

.cnrflash-inner:after {
    border-bottom-color: transparent;
    border-left-color: transparent;
    right: 0;
}

.cnrflash-label {
    bottom: 0;
    color: #fff;
    display: block;
    font-size: 0.95em;
    font-weight: bold;
    left: 0;
    padding-bottom: 5px;
    position: absolute;
    text-align: center;
    text-shadow: 0 1px 1px rgba(1, 1, 1, .8);
    width: 100%;
}

/* ==================================================
Fancy pricing styling
================================================== */
.pricing-item {
    background: #fff;
    color: #222;
    margin-bottom: 30px;
    padding: 30px;
    text-align: center;
    z-index: 1;
}

.pricing-item h3 {
    margin: 0;
}

.pricing-item hr {
    max-width: 100px;
}

.pricing-item .price {
    margin-bottom: 20px;
}

.pricing-item .price .number {
    font-size: 50px;
    font-weight: 900;
}

.pricing-item .price .number sup {
    font-size: 30px;
}

@media (min-width: 992px) {
    .pricing-item {
        margin-bottom: 0;
    }

    .pricing-item.featured-first {
        margin-right: -15px;
        margin-top: 15px;
    }

    .pricing-item.featured {
        -moz-box-shadow: 0 0 30px 5px rgba(255, 153, 51, 0.75);
        -webkit-box-shadow: 0 0 30px 5px rgba(255, 153, 51, 0.75);
        box-shadow: 0 0 30px 5px rgba(255, 153, 51, 0.75);
        left: 0;
        margin: 0;
        padding: 45px 30px;
        position: absolute;
        width: 100%;
    }

    .pricing-item.featured-last {
        margin-left: -15px;
        margin-top: 15px;
    }
}

/* ==================================================
Dark/Light options
================================================== */
.bg-dark {
    background-color: #f2f2f2;
}

.bg-light {
    background-color: #fff;
}

.bg-light,
.bg-light i.header-icon,
.bg-dark,
.bg-dark i.header-icon {
    color: #0d0d0d;
}

.bg-light .section-heading,
.bg-light h3,
.bg-dark .section-heading,
.bg-dark h3 {
    color: #404040;
}

/* ==================================================
Primary recolour
================================================== */
.form-control:focus {
    border-color: #5949c5 !important;
}

.bg-primary,
.btn-primary {
    background-color: #5949c5;
}

.hr-dark {
    border-color: #5949c5;
}

.hr-primary,
.btn-primary {
    border-color: #5949c5;
}

.hr-light {
    border-color: #fff;
}

.text-primary,
.btn-primary .badge {
    color: #fb3c3e;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary {
    background-color: #5949c5;
    opacity: .7;
    border-color: #5949c5;
    outline: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active,
.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
    background-color: #5949c5;
    border-color: #5949c5;
}

.nav-tabs-custom > .nav-tabs > li.active {
    border-top-color: #5949c5;
}

/* ==================================================
Header settings
================================================== */
header {
    background-image: url('images/freshmarkets_asparagus.png');
    background-position: center;
    -moz-background-size: cover;
    -o-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .5);
    color: #fff;
    height: 100vh;
    position: relative;
    text-align: center;
    width: 100%;
}

header .header-content {
    padding: 100px 15px;
    position: relative;
    text-align: center;
    width: 100%;
}

header .header-content .header-content-inner h1 {
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 0;
    text-transform: uppercase;
}

header .header-content .header-content-inner hr {
    margin: 30px auto;
}

header .header-content .header-content-inner p {
    color: rgba(255, 255, 255, .7);
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 50px;
}

@media(min-width:768px) {
    header {
        min-height: 100%;
    }

    header .header-content {
        padding: 0 50px;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }

    header .header-content .header-content-inner {
        margin-left: auto;
        margin-right: auto;
        max-width: 1000px;
    }

    header .header-content .header-content-inner p {
        font-size: 18px;
        margin-left: auto;
        margin-right: auto;
        max-width: 80%;
    }
}

/* ==================================================
Navbar recolour - Dropdown Menu
================================================== */
@media(max-width: 767px) {
    .navbar-default .navbar-nav>.open>a,
    .navbar-default .navbar-nav>.open>a:focus,
    .navbar-default .navbar-nav>.open>a:hover {
        background-color: #fff;
        color: #5949c5;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu >.active>a,
    .navbar-default .navbar-nav .open .dropdown-menu >.active>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu >.active>a:hover {
        background-color: #fff;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #404040;
    }

    .navbar-default .navbar-nav .open .dropdown-menu >.active>a,
    .navbar-default .navbar-nav .open .dropdown-menu >.active>a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu >.active>a:hover {
        color: #5949c5;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover {
        color: #5949c5;
    }
}

@media(min-width:768px) {
    ul.nav li.dropdown:hover > ul.dropdown-menu {
        display: block;
    }

    .navbar:not(.affix) .dropdown-menu {
        background-color: #f2f2f2;
        border-color: rgba(255, 255, 255, .3);
    }

    .navbar:not(.affix) .navbar-nav>.open>a {
        background-color: #404040;
        color: #fff;
    }

    .navbar .dropdown-menu > li > a,
    .navbar .dropdown-menu > li > a:focus,
    .navbar .dropdown-menu > li > a:hover,
    .navbar .dropdown-menu >.active>a,
    .navbar .dropdown-menu>.active>a:focus,
    .navbar .dropdown-menu>.active>a:hover {
        background-color: #f2f2f2;
    }

    .navbar .dropdown-menu > li > a {
        color: #404040;
    }

    .navbar .dropdown-menu >.active>a,
    .navbar .dropdown-menu > li > a:focus,
    .navbar .dropdown-menu > li > a:hover,
    .navbar .dropdown-menu>.active>a:focus,
    .navbar .dropdown-menu>.active>a:hover {
        color: #5949c5;
    }

    .navbar.affix .nav > .open > a,
    .navbar.affix .nav > li > a:focus {
        background-color: #fff;
        color: #5949c5;
    }

    .navbar.affix .dropdown-menu > li > a,
    .navbar.affix .dropdown-menu > li > a:focus,
    .navbar.affix .dropdown-menu > li > a:hover,
    .navbar.affix .dropdown-menu >.active>a,
    .navbar.affix .dropdown-menu>.active>a:focus,
    .navbar.affix .dropdown-menu>.active>a:hover {
        background-color: #fff;
    }

    .navbar.affix .dropdown-menu > li > a {
        color: #404040;
    }

    .navbar.affix .dropdown-menu >.active>a,
    .navbar.affix .dropdown-menu >.active>a:focus,
    .navbar.affix .dropdown-menu >.active>a:hover {
        color: #5949c5;
    }

    .navbar.affix .dropdown-menu > li > a:focus,
    .navbar.affix .dropdown-menu > li > a:hover {
        color: #5949c5;
    }
}

/* ==================================================
Navbar recolour
================================================== */
.navbar .navbar-logo:before {
    margin-top: -5px;
    height: 35px;
    width: 150px;
    position: absolute;
    content: " ";
    background-image: url('images/efgroup-logo-black.png');
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
}

.navbar .navbar-header .navbar-brand {
    color: #404040;
}

.navbar .nav > li.active>a,
.navbar .nav > li.active>a:focus {
    color: #5949c5 !important;
}

.navbar .nav > li>a:hover,
.navbar .nav > li>a:focus:hover,
.navbar .nav > li.active>a:hover,
.navbar .nav > li.active>a:focus:hover,
.navbar .navbar-header .navbar-brand:hover,
.navbar .navbar-header .navbar-brand:focus {
    color: #5949c5;
}

@media(min-width:768px) {
    .navbar .navbar-logo:before {
        background-image: url('images/efgroup-logo-white.png');
        height: 35px;
        margin-top: -5px;
        width: 150px;
    }

    .navbar-bg-primary {
        background-color: #090909;
    }
    
    .navbar .navbar-header .navbar-brand {
        color: rgba(255, 255, 255, .7);
    }

    .navbar .navbar-header .navbar-brand:hover,
    .navbar .navbar-header .navbar-brand:focus,
    .navbar .nav > li.active>a,
    .navbar .nav > li.active>a:focus,
    .navbar .nav > li>a:hover,
    .navbar .nav > li>a:focus:hover {
        color: #fff !important;
    }

    .navbar.affix .navbar-logo:before {
        background-image: url('images/efgroup-logo-black.png');
        height: 35px;
        margin-top: -5px;
        width: 150px;
    }

    .navbar.affix .navbar-header .navbar-brand {
        color: #090909;
    }

    .navbar.affix .navbar-header .navbar-brand:hover,
    .navbar.affix .navbar-header .navbar-brand:focus,
    .navbar.affix .nav > li>a:hover,
    .navbar.affix .nav > li>a:focus:hover {
        color: #5949c5;
    }

    .navbar.affix .nav > li.active>a,
    .navbar.affix .nav > li.active>a:focus {
        color: #5949c5 !important;
    }

    .skin-purple .main-header .navbar {
        background-color: #090909;
        border-bottom-color: rgba(255, 255, 255, 0.298039);
    }
}

/* ==================================================
Footer
================================================== */
footer {
    background: #090909;
    border-top: 2px solid #444;
    color: #777;
    font-family: Merriweather,'Helvetica Neue',Arial,sans-serif;
    padding: 11px 0 0 0;
}

footer section {
    padding: 0;
}

footer p {
    font-size: 14px;
}

footer h1,footer h2,footer h3,footer h4{
    color: #5949c5;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 300;
}

footer a {
    color: #fff;
    -moz-transition: all .35s;
    -webkit-transition: all .35s;
    transition: all .35s;
}

footer a:hover,
footer a:focus {
    color: #fff;
}

#footerRights {
    background-color: #333;
    border-top: 1px solid #1b1b1b;
    color: #999;
    font-size: 10px;
    margin-top: 22px;
    padding-top: 22px;
    text-align: center;
}

.footerWidget {
    margin-bottom: 22px
}

.footerWidget .boxTestimonial blockquote:after {
    border-color: rgba(51, 51, 51, 0);
    border-top-color: #333;
}

.footerWidget .boxTestimonial blockquote {
    background: #333;
    color: #eee;
}

/* ==================================================
Dropdown Submenu
================================================== */
.dropdown-submenu {
    position: relative;
}

.dropdown-submenu>.dropdown-menu {
    -moz-border-radius: 0 6px 6px;
    -webkit-border-radius: 0 6px 6px 6px;
    border-radius: 0 6px 6px 6px;
    left: 100%;
    margin-left: -1px;
    margin-top: -6px;
    right: auto;
    top: 0;
}

@media (max-width: 768px) {
    .dropdown-submenu>.dropdown-menu {
        margin-left: 15px;
    }
}

.dropdown-submenu>a:after {
    border-color: transparent;
    border-left-color: #ccc;
    border-style: solid;
    border-width: 5px 0 5px 5px;
    content: " ";
    display: block;
    float: right;
    height: 0;
    margin-right: -10px;
    margin-top: 5px;
    width: 0;
}

.dropdown-submenu.pull-left {
    float: none;
}

.dropdown-submenu.pull-left>.dropdown-menu {
    -moz-border-radius: 6px 0 6px 6px;
    -webkit-border-radius: 6px 0 6px 6px;
    border-radius: 6px 0 6px 6px;
    left: -100%;
    margin-left: 10px;
}


/* ==================================================
Icons
================================================== */
.icon-chevron-right {
    font-weight: 900;
}
